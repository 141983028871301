import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

class About extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Crearea unor stiluri de viață urbane de calitate, construirea de comunități puternice." pagename="Despre noi" bgimage={bnrimg.default}/>


                </div>

                <Footer />

            </>
        );
    };
};

export default About;