import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider from './../Elements/Slider';
import About from './../Elements/About';
import OurValue from './../Elements/OurValue';
import Callus from './../Elements/Callus';
import OurServices from './../Elements/OurServices';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <OurValue />
                    <About />
                      <Callus />
                    <OurServices />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;