import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Arhitectură`,
        icon: require('./../../images/icon/planning.png'),
        description: 'Realizăm proiecte de arhitectură, cu soluții creative, inovatoare și rentabile pentru proiecte unice ce surprind prin simplitate și funcționalitate, respectând termenele și în același timp bugetul proiectului.',
    },
    {
        count: 2,
        title: 'Proiectare Construcții',
        icon: require('./../../images/icon/building.png'),
        description: 'Realizăm proiecte de structură pentru toate tipurile de clădiri, fie ele de locuințe individuale, locuințe colective, clădiri de birouri sau clădiri industriale.',
    },
    {
        count: 2,
        title: 'Proiectare Construcții',
        icon: require('./../../images/icon/building.png'),
        description: 'Realizăm proiecte de structură pentru toate tipurile de clădiri, fie ele de locuințe individuale, locuințe colective, clădiri de birouri sau clădiri industriale.',
    },
    {
        count: 2,
        title: 'Proiectare Construcții',
        icon: require('./../../images/icon/building.png'),
        description: 'Realizăm proiecte de structură pentru toate tipurile de clădiri, fie ele de locuințe individuale, locuințe colective, clădiri de birouri sau clădiri industriale.',
    },
    {
        count: 2,
        title: 'Proiectare Construcții',
        icon: require('./../../images/icon/building.png'),
        description: 'Realizăm proiecte de structură pentru toate tipurile de clădiri, fie ele de locuințe individuale, locuințe colective, clădiri de birouri sau clădiri industriale.',
    },

    {
        count: 3,
        title: 'Asistennță Tehnică',
        icon: require('./../../images/icon/toolbox.png'),
        description: 'La aceste activități se adaugă în faza finală a lucrărilor participarea arhitectului(proiectantului) la întocmirea de către beneficiar a cărții tehnice a construcției ți recepția lucrărilor executate.',
    },

    {
        count: 4,
        title: 'Consultanță imobiliară',
        icon: require('./../../images/icon/architecture.png'),
        description: 'Avem expertiza și experiența necesară pentru a îndruma clienții de-a lungul întregului proces de dezvoltare.',
    },


]

var img1 = require('./../../images/background/bg-6.png');

class OurServices extends React.Component {
    componentDidMount(){
        function loadScript(src) {

          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };

      loadScript('./assets/js/masonary.js');

    };
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding  p-b10  square_shape2">
                    <div className="section-content">
                        <div className="Service-half-top p-t20  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                            <div className="container">
                                <div className="section-head text-white">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h3 className="text-white text-uppercase sep-line-one "><span className="font-weight-600 text-primary">Serviciile</span> Noastre</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="services-half-bottom">
                            <div className="container">
                                <div className="row">
                                    {services.map((item, index) => (
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b10">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="icon-md inline-icon m-b10 text-primary scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon.default} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b10">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Vezi mai multe <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
};


export default OurServices;